import React, { Component } from "react";
import $ from "jquery";
import NavTop from "./NavTop";

class Navbar extends Component {
    constructor(props) {
        super(props);
    }
    // componentDidMount() {
    //     $('.navbar-nav a').on('click', function () {
    //         $('.navbar-nav').find('li.active').removeClass('active');
    //         $(this).parent('li').addClass('active');

    //     });
    // }


    render() {
        return (
            <div class="sticky-top bg-white">
                    <nav class="navbar navbar-expand-lg navbar-light bg-white px-lg-5">
                        <a href="/">
                            <img class="" src={require("../images/logo.png")} height="60px" width="auto" alt="logo" ></img>
                        </a>
                        {/* <a class="navbar-brand" href="#">Navbar</a> */}
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto ">
                                <li id="home-nav-item" class="nav-item">
                                    <a class="nava-link p-2" href="/">PROFILE<span class="sr-only">(current)</span></a>
                                </li>
                                <li id="projects-nav-item" class="nav-item">
                                    <a class="nava-link p-2" href="/projects">PROJECTS</a>
                                </li>
                                <li id="skills-nav-item" class="nav-item">
                                    <a class="nava-link p-2" href="/skills">SKILLS</a>
                                </li>
                                {/*<li id="stories-nav-item" class="nav-item">
                                    <a class="nava-link p-2" href="/stories">STORIES</a>
                                </li>
                                <li id="hobbies-nav-item" class="nav-item">
                                    <a class="nava-link p-2" href="/hobbies">HOBBIES</a>
                                </li>
                                <li id="certificates-nav-item" class="nav-item">
                                    <a class="nava-link p-2" href="/certificates">CERTIFICATES</a>
                                </li>
                                <li id="career-nav-item" class="nav-item">
                                    <a class="nava-link p-2" href="/career">CAREER</a>
                                </li>*/}

                            </ul>
                        </div>
                    </nav>

            </div>
        );
    }
}

export default Navbar;
