import React, { Component } from "react";

class AboutOne extends Component {
  render() {
    return (
      <div class="container p-5">
        <div class="row p-5">

        </div>
        <div class="row p-5">

        </div>
        <div class="row p-5">
          <div class="col p-5">
            <img src="./logo-hq.png" width="250px" />
          </div>
          <div class="col p-5">
            <p> This is a text that should be added in here but as you know,
            life requires patience in order to get the destination.
            This is a text that should be added in here but as you know,
              life requires patience in order to get the destination.</p>
            <br />
            <p> -This is a text that should be added.</p>
            <p> -This is a text that should be added.</p>
          </div>
        </div>
        <div class="row p-5">

        </div>
      </div>

    );
  }
}

export default AboutOne;