import React, { Component } from "react";
import $ from "jquery";
import ToolIcon from "./ToolIcon"
import { BiStats, BiBarcodeReader } from "react-icons/bi"
import { IoGameController, IoNotifications, IoPersonAdd } from "react-icons/io5"
import { MdAnimation, MdSwipe } from "react-icons/md"
import { FaBluetooth } from "react-icons/fa"
import { BsTranslate } from "react-icons/bs"
import '../style/Common.css'

const videos = [require("../images/otus_plus/otus_plus_stats.mp4"),
require("../images/otus_plus/otus_plus_scan.mp4"),
require("../images/otus_plus/otus_plus_games.mp4"),
require("../images/otus_plus/otus_plus_condition.mp4"),
require("../images/otus_plus/otus_plus_finger_anim.mp4"),
require("../images/otus_plus/otus_plus_login.mp4"),
require("../images/otus_plus/otus_plus_notifications.mp4"),
require("../images/otus_plus/otus_plus_game_explanation.mp4"),
require("../images/otus_plus/otus_plus_member_add.mp4")
]

const texts = [
    "This stats are the users' game results daily, weekly and monthly. Only for the Pie graph I used a third party library. I, however, created the Radar chart and the bar charts from scratch.",
    "A serial card containing a bar code is scanned by the app. A third-party library is used here.",
    "The games are made using Unity and with a help of a third party library, they are exported to the React Native project. During the game Bluetooth messages are sent to the device indirectly by messaging to React Native and the latter forwards them to the target. Each game has its own features and was created from scratch without any copying (as many do). I love the challenge of creating games from scratch. Because it pushes me to think out of the box and gives me full control and understanding of my game.",
    "The video shows a vision measurement process where data is send to and receive from an external device through Bluetooth Low Energy. It is made with React Native and uses a third party library for Bluetooth feature.",
    "An animated guide of how to use the external device. The animation is achieved using React Native's Animated views.",
    "It is the login process and the language selection. There are four languages and based on the system's language one of them is selected initially. 'i18n' is used for handling language switching. A side note: after I received Excel files for each language from the translator, I created a simple Excel->Json converter to avoid mapping them one by one.",
    "These are a list of 'notifications' in the app. These are not push notifications, however. For more about push notifications check out Bana project",
    "The games are explained in a slideshow style. I love making these from scratch.",
    "The process of adding a new account. It includes entering personal info and selecting profile picture. As the profile picture are just avatars, their ID only is stored in the DB, not the actual images.",
]
class OtusPlus extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
            currentVideo: videos[0],
            currentText: texts[0],
        }
        this.handlerOnFeature = this.handlerOnFeature.bind(this);
    }

    handlerOnFeature(index, url) {
        this.setState({ currentVideo: videos[index] })
        this.setState({ currentText: texts[index] })
        this.videoRef.current.load()
    }


    componentDidMount() {
        //this.setState({currentImage: require("../images/condition.jpg")})
    }
    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
                <div class="row">
                    <div class="col mt-4">
                        <div class="d-flex justify-content-between">
                            <div class="animated bounceInLeft">
                                <img class="projectIcon" src={require("../images/otus_plus_icon.png")} />
                                <h5 class="display-5 font-weight-bold pt-2">Otus Plus</h5>
                                <p class="mb-0">(Version 2)</p>
                            </div>
                            <div class="d-flex flex-column align-items-end">

                                <a href="https://play.google.com/store/apps/details?id=com.EDENLUX.OtusPlus&hl=en&gl=US" target="_blank" class="mb-1">
                                    <img src={require("../images/gp_icon.png")} class="storeBtn" />
                                </a>
                                <a href="https://apps.apple.com/us/app/otus-plus/id1544386595" target="_blank">
                                    <img src={require("../images/as_icon.png")} class="storeBtn" />
                                </a>
                                <p class="font-weight-bold mt-2">Try it yourself</p>
                            </div>
                        </div>
                        <h5 class="mt-5"><b>Mobile App</b></h5>
                        <div class="d-flex">
                            <ToolIcon image={require("../images/react_native.png")} title="React Native" />
                            <ToolIcon image={require("../images/unity_icon.png")} title="Unity" />
                        </div>
                        <div class="">
                            <p>
                                A hybrid mobile app containing 10 games developed with <b>React Native</b> and <b>Unity</b>.<br />
                                It's a second version of the app. I developed the first version in 2020 with Unity only.
                                Though, it was fairly a good app, there was a tradeoff between app size and crispy clear user interface.
                                We, therefore, decided to recreate it from scratch.
                                With more experience, I decided to combine React Native and Unity to develop the app and the games therein respectively.
                                <br />
                                <span style={{ textDecoration: "underline" }}>Click the icons below to watch the associated video:</span><br />
                            </p>
                            <div class="d-flex flex-wrap justify-content-between">
                                <button class="d-flex align-items-center p-2 btn rounded btn-success" onClick={() => this.handlerOnFeature(0, 'notavailable')}>
                                    <BiStats size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Statistics</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-secondary" onClick={() => this.handlerOnFeature(1, '_sdKKwWaYn4')}>
                                    <BiBarcodeReader size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Barcode Scan</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-primary" onClick={() => this.handlerOnFeature(2, 'jd8YJzuusAQ')}>
                                    <IoGameController size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Games</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-info" onClick={() => this.handlerOnFeature(3, 'c29yMZZKyr8')}>
                                    <FaBluetooth size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Device Control</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-warning" onClick={() => this.handlerOnFeature(4, 'Igi6AJlGOqU')}>
                                    <MdAnimation size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Animated Guide</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-dark" onClick={() => this.handlerOnFeature(5, '9pBG7FkWPT0')}>
                                    <BsTranslate size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Multilingual</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-light" onClick={() => this.handlerOnFeature(6, 'gM7thNI4RRY')}>
                                    <IoNotifications size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Notifications</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-success" onClick={() => this.handlerOnFeature(7, 'qi87YhEFmRg')}>
                                    <MdSwipe size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Page Scroll</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-secondary" onClick={() => this.handlerOnFeature(8, 'fxCRipLB6UM')}>
                                    <IoPersonAdd size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Account Creation</p>
                                </button>
                            </div>
                            <p class="mt-3" style={{ fontSize: "15px" }}>{
                                this.state.currentText
                            }</p>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="embed-responsive embed-responsive-1by1">
                            <video autoPlay muted loop ref={this.videoRef} style={{ backgroundColor: "#fff" }}>
                                <source src={this.state.currentVideo ? this.state.currentVideo : require("../images/bana/bana_matching.mp4")} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            {/* <iframe
                                src={`https://www.youtube.com/embed/${this.state.currentVideo}?autoplay=1&mute=1&controls=0`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                
                                title="otus_plus"
                            >
                            </iframe> */}
                            {/* <div class="youtube-container">
                                <iframe src={`https://www.youtube.com/embed/${this.state.currentVideo}?autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=${this.state.currentVideo}`}
                                    title="otus_plus"
                                    class="iframe"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>

                                </iframe>
                            </div> */}
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col">
                        <h5 class=""><b>Backend</b></h5>
                        <div class="d-flex">
                            <ToolIcon image={require("../images/aws_logo.png")} title="AWS" />
                            <ToolIcon image={require("../images/python_logo.jpg")} title="Python" />
                            <ToolIcon image={require("../images/firebase_logo.png")} title="Firebase" />
                        </div>
                        <p>
                            <b>Server Structure</b><br />
                            There are multiple servers at different locations for the mobile app to connect to. All the servers are written in Python running on AWS <b>EC2</b> instances which are connected to <b>MySQL Databases</b> created with AWS <b>RDS</b>. <br />
                            During account creation, the app connects to a central Authentication server which figures out the ideal server location for the user (sometimes routing is done at the app side based on the provided information during sign up).
                            This central server is also where the user logs in at first until their location is found and get redirected. Afterwards, the ID is associated to that server and subsequent communications are faster.<br /><br />
                            <b>Firebase</b><br />
                            Firebase is used here for one purpose only: to store images send during Customer Service. As this feature is not used often, it's better to employ the Firebase free-tier storage rather than a paying AWS service.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default OtusPlus;
