import React, { Component } from "react";
import { DiCode } from "react-icons/di"
import ToolIcon from "./ToolIcon"


class ProgrammingSkills extends Component {
   constructor(props) {
      super(props);
      this.state = {
         levels: ["Novice", "Advanced Beginner", "Competent", "Proficient", "Expert"],
         fameworks: [
            {
               year: 2018,
               icon: require("../images/android_studio.png"),
               title: "Android Studio",
               level: "Expert"
            },
            {
               year: 2021,
               icon: require("../images/react_native.png"),
               title: "React Native",
               level: "Expert"
            },
            {
               year: 2020,
               icon: require("../images/unity_icon.png"),
               title: "Unity",
               level: "Expert"
            },
            {
               year: 2020,
               icon: require("../images/reactjs_icon.png"),
               title: "React",
               level: "Expert"
            },
            {
               year: 2020,
               icon: require("../images/spring_icon.png"),
               title: "Spring Boot",
               level: "Expert"
            },
            {
               year: 2017,
               icon: require("../images/arduino_logo.png"),
               title: "Arduino",
               level: "Expert"
            },
         ],
         languages: [
            {
               year: 2018,
               icon: require("../images/java_logo.png"),
               title: "Java",
               level: "Expert"
            },
            {
               year: 2018,
               icon: require("../images/javascript.jpg"),
               title: "JavaScript",
               level: "Expert"
            },
            {
               year: 2020,
               icon: require("../images/cs_logo.png"),
               title: "C#",
               level: "Expert"
            },
            {
               year: 2020,
               icon: require("../images/python_logo.png"),
               title: "Python",
               level: "Proficient"
            },
            {
               year: 2019,
               icon: require("../images/scala_logo.png"),
               title: "Scala",
               level: "Competent"
            },
            {
               year: 2019,
               icon: require("../images/cpp_logo.png"),
               title: "C++",
               level: "Proficient"
            },
            {
               year: 2019,
               icon: require("../images/c_logo.png"),
               title: "C",
               level: "Competent"
            },
         ],
         databases: [
            {
               year: 2019,
               icon: require("../images/firebase_logo.png"),
               title: "Firebase",
               level: "Expert"
            },
            {
               year: 2020,
               icon: require("../images/aws_logo.png"),
               title: "AWS(EC2, RDS, S3)",
               level: "Expert"
            },
            {
               year: 2019,
               icon: require("../images/mysql_logo.png"),
               title: "MySQL",
               level: "Expert"
            },
            {
               year: 2021,
               icon: require("../images/apache_icon.png"),
               title: "Apache2",
               level: "Proficient"
            },
            {
               year: 2021,
               icon: require("../images/nginx_logo.png"),
               title: "Nginx",
               level: "Proficient"
            }
         ]
      }
   }
   render() {
      return (
         <div class="container-fluid p-lg-5">
            <div class="row">
               <div class="col col-lg-4 mt-4 skillHeader">
                  <div class="d-flex align-items-center">
                     <DiCode size={40} color="#20c997" />
                     <h4 class="display-5 font-weight-bold pt-2">Software Development</h4>
                  </div>
               </div>

            </div>
            <div class="row">
               <div class="col pl-4 skillSection">
                  <p class="font-weight-bold mb-3" ><b>1. Frameworks, Engines and IDEs</b></p>
                  <div class="d-flex flex-wrap">
                     {this.state.fameworks.map((item, i) => (
                        <Card props={item} key={i} />
                     ))}
                  </div>

                  <p class="font-weight-bold mb-3 mt-3" ><b>2. Programming Languages</b></p>
                  <div class="d-flex flex-wrap">
                     {this.state.languages.map((item, i) => (
                        <Card props={item} key={i} />
                     ))}
                  </div>
                  <p class="font-weight-bold mb-3 mt-3" ><b>3. Databases and Tools</b></p>
                  <div class="d-flex flex-wrap">
                     {this.state.databases.map((item, i) => (
                        <Card props={item} key={i} />
                     ))}
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

const Card = ({ props }) => {
   return (
      <div class="skill">
         <div class="d-flex">
            <ToolIcon image={props.icon} title={props.title} />
         </div>
         <div class="" style={{ fontSize: "12px" }}>
            <p>
               In use since: <b>{props.year}</b>
            </p>
            <p>
               Expertise: <b>{props.level}</b>
            </p>
         </div>
      </div>
   )
}

export default ProgrammingSkills;
