import React, { Component } from "react";

class AboutTwo extends Component {
  render() {
    return (
     <div >
        <img src="./about_image_1.png" width="100%"/>

     </div>
    );
  }
}

export default AboutTwo;