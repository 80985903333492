import React, { Component } from "react";

import ContactButtons from "../components/ContactButtons";
import $ from "jquery";
import AppOne from "../components/AppOne";
import AppTwo from "../components/AppTwo";
import AppThree from "../components/AppThree";
import AppButtons from "../components/AppButtons";
import AppFour from "../components/AppFour";
import Navbar from "../components/NavBar";
import NavTop from "../components/NavTop";

class Apps extends Component {
    componentDidMount(){
        $(document).ready(function(){
            $(".actif").removeClass("actif");
            $("#app-nav-item a").addClass("actif");
         });
    }
    render() {
        return (
            <div>
                <NavTop/>
                <Navbar/>
                <AppOne />
                <AppTwo />
                <AppThree />
                <AppFour />
                <AppButtons />
                
            </div>
        );
    }
}

export default Apps;