import React, { Component } from "react";
import HomeTwo from "../components/HomeTwo";
import ContactButtons from "../components/ContactButtons";
import $ from "jquery";
import AboutOne from "../components/AboutOne";
import AboutTwo from "../components/AboutTwo";
import Navbar from "../components/NavBar";
import NavTop from "../components/NavTop";

class About extends Component {
    componentDidMount(){
        $(document).ready(function(){
            $(".actif").removeClass("actif");
            $("#about-nav-item a").addClass("actif");
         });
    }
    render() {
        return (
            <div>
                <NavTop/>
                <Navbar/>
                <AboutOne />
                <AboutTwo/>
                <ContactButtons />
            </div>
        );
    }
}

export default About;