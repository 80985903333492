import React, { Component } from "react";
import CSOne from "../components/CSOne";
import ContactButtons from "../components/ContactButtons";
import $ from "jquery";
import Navbar from "../components/NavBar";
import NavTop from "../components/NavTop";

class CS extends Component {
    componentDidMount(){
        $(document).ready(function(){
            $(".actif").removeClass("actif");
            $("#cs-nav-item a").addClass("actif");
         });
    }
    render() {
        return (
            <div>
                <NavTop/>
                <Navbar/>
                <CSOne />
                <ContactButtons />
            </div>
        );
    }
}

export default CS;