import React, { Component } from "react";
import ContactButtons from "../components/ContactButtons";
import $ from "jquery";
import OtusPlus from "../components/OtusPlus";
import OtusPlusOld from "../components/OtusPlusOld";
import OtusPlusReport from "../components/OtusPlusReport";
import Bana from "../components/Bana";
import LPOld from "../components/LPOld";
import GameOfLife from "../components/GameOfLife";
import BanaOld from "../components/BanaOld";
import LudoPrison from "../components/LudoPrison";
import BottleFilled from "../components/BottleFilled";
import NavBar from "../components/NavBar";
import NavTop from "../components/NavTop";
import TagBar from "../components/TagBar";
import HappyPet from "../components/HappyPet";

const hp_tags = ["App", "React Native", "Firebase", "Live Stream", "Chat", "Posting", "All"];
const op_tags = ["App", "Game", "React Native", "Unity", "Firebase", "AWS", "In Production", "Bluetooth", "All"];
const oop_tags = ["App", "Game", "Unity", "AWS", "Bluetooth", "All"];
const bn_tags = ["App", "React Native", "Firebase", "Chat", "Facebook Login", "Phone Login", "All"]
const obn_tags = ["App", "Android Studio", "Firebase", "Chat", "Facebook Login", "Phone Login", "All"]
const lp_tags = ["Game", "Unity", "Firebase", "Chat", "In Production", "Facebook Login", "All"]
const olp_tags = ["Game", "Android Studio", "Firebase", "Facebook Login", "All"]
const bf_tags = ["Game", "Unity", "In Production", "All"]
const gol_tags = ["Game", "Unity", "All"]
const opr_tags = ["Website", "React JS", "Springboot", "All"]

class Projects extends Component {

    constructor(props){
      super(props);
      this.state = {
        currentTag: "All",
      }
      this.handlerOnTag = this.handlerOnTag.bind(this);
    }

    handlerOnTag (e){
      console.log("current tag: "+e)
      this.setState({currentTag: e})
    }
    componentDidMount(){
        $(document).ready(function(){
            $(".actif").removeClass("actif");
            $("#projects-nav-item a").addClass("actif");
         });
    }

    render() {
        return (
            <div>
                {/*<NavTop/>*/}
                <NavBar/>
                <TagBar callbackOnTag={this.handlerOnTag}/>
                {hp_tags.includes(this.state.currentTag) && <HappyPet/>}
                {op_tags.includes(this.state.currentTag) && <OtusPlus/>}
                {bn_tags.includes(this.state.currentTag) && <Bana/>}
                {lp_tags.includes(this.state.currentTag) && <LudoPrison/>}
                {bf_tags.includes(this.state.currentTag) && <BottleFilled/>}
                {opr_tags.includes(this.state.currentTag) && <OtusPlusReport/>}
                {gol_tags.includes(this.state.currentTag) && <GameOfLife/>}
                {oop_tags.includes(this.state.currentTag) && <OtusPlusOld/>}
                {obn_tags.includes(this.state.currentTag) && <BanaOld/>}
                {olp_tags.includes(this.state.currentTag) && <LPOld/>}
                <ContactButtons/>
            </div>
        );
    }
}

export default Projects;
