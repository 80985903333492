import React, { Component } from "react";


class NewsHeader extends Component {
  render() {
    return (
      <div>
      <img class="pb-2" src="./news_badge.png" width="100%" height="auto" style={{ marginTop: "-3%" }} />
      </div>
    );
  }
}

export default NewsHeader;