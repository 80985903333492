import React, { Component } from "react";

class HomeTwo extends Component {
  render() {
    return (
      <section class="containerTwo container-xl overflow-hidden p-md-5 bg-light">
        <h5 class="animated fadeInDown mb-3">Thank you for using our product. We're glad you're with us.</h5>
        <div class="col-md-5 p-lg-5 my-5">
        <br />
        <br /><br /><br />
          <h1 class="display-5 font-weight-bold">눈 건강의 모든 것, <br /> 내 눈을 위한 선물, Otus</h1>
          
          <div class="download-app-buttons">
          <br />
          
          <a href="https://apps.apple.com/kr/app/otus-%EC%98%A4%ED%88%AC%EC%8A%A4/id1443712683?l=en">
            <img class="animated fadeInDown appstore-button" src="app_store_button.png" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.EDENLUX.OtusApp">
            <img class="googleplay-button" src="google_play_button.png" />
          </a>
          <br />
          <div class="col-md-5 p-lg-5 mx-auto my-5">
          <br /><br /><br />
        </div>
        </div>
        </div>


        <div class="product-device shadow-sm d-none d-md-block"></div>
        <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
      </section>
    );
  }
}

export default HomeTwo;