import React, { Component } from "react";
import ContactButtons from "../components/ContactButtons";
import $ from "jquery";
import ProgrammingSkills from "../components/ProgrammingSkills";
import LanguageSkills from "../components/LanguageSkills";
import NavBar from "../components/NavBar";

class Skills extends Component {
    componentDidMount(){
        $(document).ready(function(){
            $(".actif").removeClass("actif");
            $("#skills-nav-item a").addClass("actif");
         });
    }
    render() {
        return (
            <div>
                <NavBar/>
                <ProgrammingSkills />
                <LanguageSkills/>
                <ContactButtons />
            </div>
        );
    }
}

export default Skills;
