import React, { Component } from "react";
import $ from "jquery";
import ToolIcon from "./ToolIcon"
import { MdPostAdd } from "react-icons/md"
import '../style/Common.css'

const videos = [require("../images/happy_pet/happy_pet_post.mp4"),
]

const texts = [
    "This shows the process of writing a new post with title, content and pictures. Afterwards, the post can be commented and the comments can be replied to. Finally, the post and the comments can be modified or deleted.",]
class HappyPet extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
            currentVideo: videos[0],
            currentText: texts[0],
        }
        this.handlerOnFeature = this.handlerOnFeature.bind(this);
    }

    handlerOnFeature(index, url) {
        this.setState({ currentVideo: videos[index] })
        this.setState({ currentText: texts[index] })
        this.videoRef.current.load()
    }


    componentDidMount() {
        //this.setState({currentImage: require("../images/condition.jpg")})
    }
    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
                <div class="row">
                    <div class="col mt-4">
                        <div class="d-flex justify-content-between">
                            <div class="animated bounceInLeft">
                                <img class="projectIcon" src={require("../images/happy_pet/happy_pet_icon.png")} />
                                <h5 class="display-5 font-weight-bold pt-2">HappyPet</h5>
                                <p class="mb-0">(V.1)</p>
                            </div>
                            <div class="d-flex flex-column align-items-end">

                                <a href="#" class="mb-1">
                                    <img src={require("../images/gp_icon.png")} class="storeBtn" />
                                </a>
                                <p class="font-weight-bold mt-2">Coming soong</p>
                            </div>
                        </div>
                        <h5 class="mt-5"><b>Mobile App</b></h5>
                        <div class="d-flex">
                            <ToolIcon image={require("../images/react_native.png")} title="React Native" />
                        </div>
                        <div class="">
                            <p>
                                A hybrid mobile app developed with <b>React Native</b> and <b>Firebase</b>.<br />
                                It is a pet sitting service which includes many advanced features such as live streaming, instant messaging, posting, payment, points and matching.
                                <br />
                                <span style={{ textDecoration: "underline" }}>Click the icons below to watch the associated video:</span><br />
                            </p>
                            <div class="d-flex flex-wrap justify-content-between">
                                <button class="d-flex align-items-center p-2 btn rounded btn-success" onClick={() => this.handlerOnFeature(0, 'notavailable')}>
                                    <MdPostAdd size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Add Post</p>
                                </button>
                            </div>
                            <p class="mt-3" style={{ fontSize: "15px" }}>{
                                this.state.currentText
                            }</p>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="embed-responsive embed-responsive-1by1">
                            <video autoPlay muted loop ref={this.videoRef} style={{ backgroundColor: "#fff" }}>
                                <source src={this.state.currentVideo ? this.state.currentVideo : require("../images/bana/bana_matching.mp4")} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            {/* <iframe
                                src={`https://www.youtube.com/embed/${this.state.currentVideo}?autoplay=1&mute=1&controls=0`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                
                                title="otus_plus"
                            >
                            </iframe> */}
                            {/* <div class="youtube-container">
                                <iframe src={`https://www.youtube.com/embed/${this.state.currentVideo}?autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=${this.state.currentVideo}`}
                                    title="otus_plus"
                                    class="iframe"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>

                                </iframe>
                            </div> */}
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col">
                        <h5 class=""><b>Backend</b></h5>
                        <div class="d-flex">
                            <ToolIcon image={require("../images/firebase_logo.png")} title="Firebase" />
                        </div>
                        <p>
                            <b>Firebase Firestore</b><br />
                            The app stores data to Firestore directly and indirectly through Cloud Functions.<br/>
                            <b>Firebase Functions</b><br />
                            The Functions, written in JavaScript, handles the complex operations and operations that need to be processed and verified.
                            E.g. KakaoLogin (a third party auth. service not supported directly by Firebase) provides a token and it needs to be converted to a Firebase Auth token to sign in/up the user.<br/>
                            <b>Firebase Messaging</b><br />
                            Combined with the Firebase Functions, Firebase Messaging is used to notify the app in event of instant messaging or a direct notifications sent through the Admin website..<br/>
                            <b>Firebase Authentication</b><br />
                            Used to login the user through email and password or via a third party authentication token.<br/>
                            <b>Firebase Storage</b><br />
                            It serves to store the images and videos shared through the app.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default HappyPet;
