import React, { Component } from "react";


class NewsOne extends Component {
  render() {
    return (
      <div>
        <div class="container-fluid">

          <div class="row px-xl-5 justify-content-center">
            <div class="col-xl-3 col-10 p-3">
              <a href="#"><img class="w-100 py-2 news-image" src="./news_image_1.png" /></a>
              <a href="#"><h6 class="font-weight-bold pt-1" style={{ fontSize: "1vw" }}>IPO 앞둔 뉴아인, 45억원 시리즈B 투자 유치…전자약 임상시험 추진</h6></a>
              <p class="" style={{ fontSize: "0.5em" }}>4월 2nd, 2020</p>
              <p class="" style={{ fontSize: "0.5em" }}>기업공개(IPO)를 앞둔 뉴아인은 파트너스인베스트먼트, 메디치인베스트먼트 등으로부터 45억 원 규모의 시리즈B 투자를 유치했다고 16일 밝혔다. 앞서 [...]</p>
            </div>
            <div class="col-xl-3 col-10 p-3">
              <a href="#"><img class="w-100 py-2 news-image" src="./news_image_2.png" /></a>
              <a href="#"><h6 class="font-weight-bold pt-1" style={{ fontSize: "1vw" }}>IPO 앞둔 뉴아인, 45억원 시리즈B 투자 유치…전자약 임상시험 추진</h6></a>
              <p class="" style={{ fontSize: "0.5em" }}>4월 2nd, 2020</p>
              <p class="" style={{ fontSize: "0.5em" }}>기업공개(IPO)를 앞둔 뉴아인은 파트너스인베스트먼트, 메디치인베스트먼트 등으로부터 45억 원 규모의 시리즈B 투자를 유치했다고 16일 밝혔다. 앞서 [...]</p>
            </div>
            <div class="col-xl-3 col-10  p-3">
              <a href="#"><img class="w-100 py-2 news-image" src="./news_image_3.png" /></a>
              <a href="#"><h6 class="font-weight-bold pt-1" style={{ fontSize: "1vw" }}>IPO 앞둔 뉴아인, 45억원 시리즈B 투자 유치…전자약 임상시험 추진</h6></a>
              <p class="" style={{ fontSize: "0.5em" }}>4월 2nd, 2020</p>
              <p class="" style={{ fontSize: "0.5em" }}>기업공개(IPO)를 앞둔 뉴아인은 파트너스인베스트먼트, 메디치인베스트먼트 등으로부터 45억 원 규모의 시리즈B 투자를 유치했다고 16일 밝혔다. 앞서 [...]</p>
            </div>
            <div class="col-xl-3 col-10  p-3">
              <a href="#"><img class="w-100 py-2 news-image" src="./news_image_1.png" /></a>
              <a href="#"><h6 class="font-weight-bold pt-1" style={{ fontSize: "1vw" }}>IPO 앞둔 뉴아인, 45억원 시리즈B 투자 유치…전자약 임상시험 추진</h6></a>
              <p class="" style={{ fontSize: "0.5em" }}>4월 2nd, 2020</p>
              <p class="" style={{ fontSize: "0.5em" }}>기업공개(IPO)를 앞둔 뉴아인은 파트너스인베스트먼트, 메디치인베스트먼트 등으로부터 45억 원 규모의 시리즈B 투자를 유치했다고 16일 밝혔다. 앞서 [...]</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsOne;