import React, { Component } from "react";

class AppButtons extends Component {
    render() {
        return (
            <div class="app-buttons container-fluid d-xl-none d-sm-block" style={{ bottom: "0", position: "fixed" }}>
                <div class="row ">
                    <div class="col d-flex justify-content-center py-3" style={{ backgroundColor: "#ff349c" }}>
                        <a href="https://apps.apple.com/kr/app/otus-%EC%98%A4%ED%88%AC%EC%8A%A4/id1443712683?l=en"><img class="align-self-center" src="./apple_logo_text.png" style={{ width: "auto", height: "50px" }} /></a>
                    </div>
                    <div class="col  d-flex justify-content-center py-3" style={{ backgroundColor: "#ff349c" }}>
                        <a href="https://play.google.com/store/apps/details?id=com.EDENLUX.OtusApp"><img class="align-self-center" src="./googleplay_logo_text.png" style={{ width: "auto", height: "50px" }} /></a>
                    </div>
                </div>
                {/* <a href="https://apps.apple.com/kr/app/otus-%EC%98%A4%ED%88%AC%EC%8A%A4/id1443712683?l=en">
                    <img class="" src="app_store_orange.png" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.EDENLUX.OtusApp">
                    <img class="" src="google_paly_orange.png" /></a> */}
            </div>
        );
    }
}

export default AppButtons;