import React, { Component } from "react";

class AppOne extends Component {
  render() {
    return (
      <div class="appOne container-fluid p-md-5 ">
        <div class="row justify-content-md-center my-md-5 py-md-5">
          <div class="col my-md-5 content-md-center">
            <img class="mx-auto d-block animated fadeInUp" src="./otus_title_white.png" style={{width:"200px", height:"auto"}}/>
            {/* <h1 class="otusTitle animated fadeInUp display-5 font-weight-bold text-center text-white"> 내 눈을 위한 <br /> 선물</h1> */}
          </div>
          <div class="col-xl-1">

          </div>
          <div class="col my-5">
            <h1 class="animated fadeInUp display-2 font-weight-bold text-center text-white">오투스</h1>
            <div class="row mb-5 d-none d-xl-inline-flex">
              <div class="col">
                <a href="https://apps.apple.com/kr/app/otus-%EC%98%A4%ED%88%AC%EC%8A%A4/id1443712683?l=en">
                  <img class="animated fadeInUp w-100" src="app_store_orange.png" />
                </a>
              </div>
              <div class="col">
                <a href="https://play.google.com/store/apps/details?id=com.EDENLUX.OtusApp">
                  <img class="animated fadeInUp w-100" src="google_play_orange.png" />
                </a>
              </div>

            </div>
          </div>
        </div>
        <div class="row p-xl-5">

        </div>

      </div>
    );
  }
}

export default AppOne;