import React, { Component } from "react";
import $ from "jquery";


class AppThree extends Component {
  render() {
    return (

      <div class="container-fluid p-md-5">

        <div class="row mx-5 py-md-5 my-5">
          <div class="col-md justify-content-center">
            <h1 class="display-3 font-weight-bold text-center">실시간 성과와 <br />
          광고 분석을 한눈에</h1>

            <h2 class="text-center">Just some content in here so that it looks great</h2>
            <div class="w-100 d-none d-xl-block" style={{ bottom: "0", position: "absolute" }}>
              <a href="#" >
                <img class="animated fadeInUp w-50 px-1" src="app_store_orange.png" />
              </a>
              <a href="#">
                <img class="animated fadeInUp w-50 px-1" src="google_play_orange.png" />
              </a>
            </div>
          </div>
          <div class="col-md">
            <img class="w-100" src="../chart_example.png" />
          </div>
        </div>

      </div>




    );
  }
}

export default AppThree;