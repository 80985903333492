import React, { Component } from "react";
import "../style/Join.css"

class Join extends Component {

    render() {
        return (
            <div>
                <div class="container join-main">
                    <div class="py-5 text-center">
                        <img class="d-block mx-auto mb-4" src="../logo-hq.png" alt="" width="auto" height="72" />
                        <p class="lead">Join and login to gain 10,000 P.</p>
                    </div>


                    <div class="col">
                        <h4 class="mb-3">Personal Info</h4>
                        <form class="needs-validation" novalidate>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="firstName">First name</label>
                                    <input type="text" class="form-control" id="firstName" placeholder="" value="" required />
                                    <div class="invalid-feedback">
                                        Valid first name is required.
                                        </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="lastName">Last name</label>
                                    <input type="text" class="form-control" id="lastName" placeholder="" value="" required />
                                    <div class="invalid-feedback">
                                        Valid last name is required.
                                        </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="username">Username</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">@</span>
                                    </div>
                                    <input type="text" class="form-control" id="username" placeholder="Username" required />
                                    <div class="invalid-feedback" style={{ width: "100%" }}>
                                        Your username is required.
                                        </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="password">Password <span class="text-muted"></span></label>
                                    <input type="password" class="form-control" id="password" placeholder="*******" />
                                    <div class="invalid-feedback">
                                        Please enter a valid password.
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="confirmation">Confirm Password <span class="text-muted"></span></label>
                                    <input type="password" class="form-control" id="confirmation" placeholder="*******" />
                                    <div class="invalid-feedback">
                                        Please enter a valid password.
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="email">Email <span class="text-muted">(Optional)</span></label>
                                <input type="email" class="form-control" id="email" placeholder="you@example.com" />
                                <div class="invalid-feedback">
                                    Please enter a valid email address.
                                    </div>
                            </div>

                            <hr class="mb-4" />
                            <button class="btn btn-primary btn-lg btn-block" type="submit">Join</button>
                        </form>
                    </div>

                </div>
            </div>
        );
    }
}

export default Join;