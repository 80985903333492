import React, { Component } from "react";

class TagBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          currentTag: "All",
          tags: ["All", "App", "Game", "Website", "Live Stream", "Chat", "React Native", "Unity", "Android Studio", "React JS", "Springboot", "Firebase", "AWS", "Facebook Login", "Phone Login", "Bluetooth"]
        }
        this.handlerOnTag = this.handlerOnTag.bind(this);
    }

    handlerOnTag(e){
      this.setState({currentTag: e})
      this.props.callbackOnTag(e)
    }

    render() {
        return (
          <div class="container-fluid rowShadow py-2 px-lg-5">
          <div class="row">
              <div class="col d-flex justify-content-between flex-wrap">
              {this.state.tags.map((value, idx)=>{
                return(
                  <a class={this.state.currentTag === value ? "tag tagOn" : "tag"} onClick={()=> this.handlerOnTag(value)}>
                    <p  class="m-0 text-center">
                      {value}
                    </p>
                  </a>
                )
              })}
          </div>
          </div>
          </div>
        );
    }
}

export default TagBar;
