import React, { Component } from "react";
import ToolIcon from "./ToolIcon"

class OtusPlusOld extends Component {
    constructor(props) {
        super(props);
    }



    componentDidMount(){
        //this.setState({currentImage: require("../images/condition.jpg")})
    }
    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
            <div class="row">
                <div class="col mt-4">
                   <div class="d-flex justify-content-between">
                      <div class="animated bounceInLeft">
                         <img class="projectIcon projectIconExtra" src={require("../images/old_op_logo.png")} />
                         <h5 class="display-5 font-weight-bold pt-2">Otus Plus</h5>
                         <p class="mb-0">(Version 1)</p>
                      </div>
                      <div class="d-flex flex-column align-items-end">

                         <a href="https://play.google.com/store/apps/details?id=com.EDENLUX.OtusPlus&hl=en&gl=US" target="_blank" class="mb-1">
                             <img src={require("../images/gp_icon.png")} class="storeBtn"/>
                         </a>
                         <a href="https://apps.apple.com/us/app/otus-plus/id1544386595" target="_blank">
                             <img src={require("../images/as_icon.png")} class="storeBtn"/>
                         </a>
                         <p class="font-weight-bold mt-2">Try it yourself</p>
                      </div>
                   </div>
                   <h5 class="mt-5"><b>Mobile App</b></h5>
                   <div class="d-flex">
                       <ToolIcon image={require("../images/unity_icon.png")} title="Unity"/>
                   </div>
                   <div class="">
                        <p>
                           A <b>Unity</b> app containing 9 games.<br/>
                           It's the first version of the app. I developed it in 2020 with Unity only.
                           Though, it was fairly a good app, there was a tradeoff between app size and crispy clear user interface.
                           We, therefore, decided to recreate it from scratch.
                           With more experience, I decided to combine React Native and Unity to develop another app and the games therein respectively.
                           <br/>
                        </p>
                   </div>
                </div>
                <div class="col-lg">
                     <div class="embed-responsive embed-responsive-1by1">
                     <video autoPlay muted loop style={{backgroundColor:"#fff"}}>
                         <source src={require("../images/otus_plus/old_otus_plus.mp4")} type="video/mp4"/>
                         Your browser does not support the video tag.
                     </video>
                     </div>
                </div>

            </div>
            <div class="row">
                <div class="col">
                   <h5 class=""><b>Backend</b></h5>
                   <div class="d-flex">
                      <ToolIcon image={require("../images/aws_logo.png")} title="AWS"/>
                      <ToolIcon image={require("../images/python_logo.jpg")} title="Python"/>
                      <ToolIcon image={require("../images/firebase_logo.png")} title="Firebase"/>
                  </div>
                  <p>
                      <b>Server Structure</b><br/>
                      There are multiple servers at different locations for the mobile app to connect to. All the servers are written in Python running on AWS <b>EC2</b> instances which are connected to <b>MySQL Databases</b> created with AWS <b>RDS</b>. <br/>
                      During account creation, the app connects to a central Authentication server which figures out the ideal server location for the user (sometimes routing is done at the app side based on the provided information during sign up).
                      This central server is also where the user logs in at first until their location is found and get redirected. Afterwards, the ID is associated to that server and subsequent communications are faster.<br/><br/>
                      <b>Firebase</b><br/>
                      Firebase is used here for one purpose only: to store images send during Customer Service. As this feature is not used often, it's better to employ the Firebase free-tier storage rather than a paying AWS service.
                  </p>
               </div>
            </div>
          </div>
        );
    }
}

export default OtusPlusOld;
