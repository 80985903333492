import React, { Component } from "react";
import ToolIcon from "./ToolIcon"

class GameOfLife extends Component {
    constructor(props) {
        super(props);
    }


    componentDidMount(){
        //this.setState({currentImage: require("../images/condition.jpg")})
    }
    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
            <div class="row">
                <div class="col mt-4">
                   <div class="d-flex justify-content-between">
                      <div class="animated bounceInLeft">
                         <img class="projectIcon projectIconExtra" src={require("../images/logo.png")} />
                         <h5 class="display-5 font-weight-bold pt-2">Game Of Life</h5>
                      </div>
                   </div>
                   <h5 class="mt-5"><b>Mobile Game</b></h5>
                   <div class="d-flex">
                       <ToolIcon image={require("../images/unity_icon.png")} title="Unity"/>
                   </div>
                   <div class="">
                        <p>
                           A zero-player mobile game developed with <b>Android Studio</b>.<br/>
                           Out of curiosity, I developed this game in less than two hours after hearing about it in a Youtube video.
                           It's a grid of cells which can be dead or alive. A dead cell, in this case, is black. A live one has a range of colors except black, in this case.
                           At first, all cells are dead until given life. Based on few rules, a live cell can become dead and vice-versa.<br/>
                           The rules are: <br/>
                           1. Any live cell with fewer than two live neighbours dies.<br/>
                           2. Any live cell with two or three live neighbours lives on to the next generation.<br/>
                           3. Any live cell with more than three live neighbours dies.<br/>
                           4. Any dead cell with exactly three live neighbours becomes alive.<br/>
                           <br/>
                        </p>

                   </div>
                </div>
                <div class="col-lg">
                     <div class="embed-responsive embed-responsive-1by1">
                     <video autoPlay muted loop style={{backgroundColor:"#fff"}}>
                         <source src={require("../images/game_of_life/game_of_life.mp4")} type="video/mp4"/>
                         Your browser does not support the video tag.
                     </video>
                     </div>
                </div>
            </div>
         </div>
        );
    }
}

export default GameOfLife;
