import React, { Component } from "react";
 import "../style/Login.css"

class Login extends Component {

    render() {
        return (
            <div class="bgApp">
                <div class="loginCard text-center shadow p-3 mb-5 bg-white rounded">
                    <img class="login-logo " src="../otus-logo.png" alt="logo"></img>
                    <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
                    <form onSubmit={this.handlerSubmit}>
                        <label htmlFor="inputEmail" className="sr-only">Email address</label>
                        <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required autoFocus onChange={this.handlerEmail}></input>
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <input type="password" id="inputPassword" className="form-control" placeholder="Password" required onChange={this.handlerPassword}></input>
                        <div class="checkbox mb-3 mt-2">
                            <label>
                                <input type="checkbox" value="remember-me" /> Remember me
                            </label>
                        </div>
                        <button className="loginBtn btn btn-lg btn-primary btn-block " type="submit">Sign in</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;