import React, { Component } from "react";
import $ from "jquery";
import ToolIcon from "./ToolIcon"
import {IoGameController, IoSettings, IoPersonAdd} from "react-icons/io5"
import {MdSwipe} from "react-icons/md"
import {FaInternetExplorer} from "react-icons/fa"
import { IoIosSave } from "react-icons/io"

const videos = [
require("../images/ludo_prison/ludo_prison_swipe.mp4"),
require("../images/ludo_prison/ludo_prison_game.mp4"),
require("../images/ludo_prison/ludo_prison_online_game.mp4"),
require("../images/ludo_prison/ludo_prison_add_player.mp4"),
require("../images/ludo_prison/ludo_prison_game_save.mp4"),
require("../images/ludo_prison/ludo_prison_settings.mp4"),
]
const texts = [
  "The video shows a game mode selection made in a swiping style. As I said previously, I create these from scratch.",
  "This is a computer mode where the user plays with one or multiple computers (Aliens). I translated my game plan into \"ifs\" and \"elses\" and it's actually kind of me playing when one is playing with computer.",
  "There are two online modes: with a selected friend or randomly. The video shows friend search and selection. After clicking the play button, the game starts. Firestore is used for the online game.",
  "After starting a game, a user can be added or removed. At least two players are required however.",
  "A game, local or online, can be closed and resumed at anytime.",
  "A simple settings dialog where sound and game mode can be set.",
]
class LudoPrison extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
          currentVideo: videos[0],
          currentText: texts[0],
        }
        this.handlerOnFeature = this.handlerOnFeature.bind(this);
    }

    handlerOnFeature(e){
      this.setState({currentVideo: videos[e]})
      this.setState({currentText: texts[e]})
      this.videoRef.current.load()
    }


    componentDidMount(){
        //this.setState({currentImage: require("../images/condition.jpg")})
    }
    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
            <div class="row">
                <div class="col mt-4">
                   <div class="d-flex justify-content-between">
                      <div class="animated bounceInLeft">
                         <img class="projectIcon projectIconExtra" src={require("../images/ludo_prison.png")} />
                         <h5 class="display-5 font-weight-bold pt-2">Ludo Prion</h5>
                         <p class="mb-0">(Version 2)</p>
                      </div>
                      <div class="d-flex flex-column align-items-end">

                         <a href="https://play.google.com/store/apps/details?id=com.ibrahimawane.africanludo&hl=en_US&gl=US" target="_blank" class="mb-1">
                             <img src={require("../images/gp_icon.png")} class="storeBtn"/>
                         </a>
                         <p class="font-weight-bold mt-2">Try it yourself</p>
                      </div>
                   </div>
                   <h5 class="mt-5"><b>Mobile Game</b></h5>
                   <div class="d-flex">
                       <ToolIcon image={require("../images/unity_icon.png")} title="Unity"/>
                   </div>
                   <div class="">
                        <p>
                           A board and dice game developed with <b>Unity</b>.<br/>
                           It's a second version of the app. I developed the first version in 2019 with Android Studio.
                           As it became popular, I knew I had to recreate it with Unity for better design and control (specially when adding a new feature).
                           I, therefore, decided to recreate it from scratch.
                           <br/>
                           <span style={{textDecoration: "underline"}}>Click the icons below to watch the associated video:</span><br/>
                        </p>
                        <div class="d-flex flex-wrap justify-content-between">
                        <button class="d-flex align-items-center p-2 btn rounded btn-success" onClick={()=> this.handlerOnFeature(0)}>
                            <MdSwipe size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Swiping</p>
                        </button>
                        <button class="d-flex align-items-center p-2 btn rounded btn-primary" onClick={()=> this.handlerOnFeature(1)}>
                            <IoGameController size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Game Auto</p>
                        </button>
                        <button class="d-flex align-items-center p-2 btn rounded btn-secondary" onClick={()=> this.handlerOnFeature(2)}>
                            <FaInternetExplorer size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Online Multiplayer</p>
                        </button>
                        <button class="d-flex align-items-center p-2 btn rounded btn-info" onClick={()=> this.handlerOnFeature(3)}>
                            <IoPersonAdd size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Add/Remove Player</p>
                        </button>
                        <button class="d-flex align-items-center p-2 btn rounded btn-warning" onClick={()=> this.handlerOnFeature(4)}>
                            <IoIosSave size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Saving Game</p>
                        </button>
                        <button class="d-flex align-items-center p-2 btn rounded btn-dark" onClick={()=> this.handlerOnFeature(5)}>
                            <IoSettings size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Settings</p>
                        </button>
                        </div>
                        <p class="mt-3" style={{fontSize:"15px"}}>{
                          this.state.currentText
                        }</p>
                   </div>
                </div>
                <div class="col-lg">
                     <div class="embed-responsive embed-responsive-1by1">
                     <video autoPlay muted loop ref={this.videoRef} style={{backgroundColor:"#fff"}}>
                         <source src={this.state.currentVideo} type="video/mp4"/>
                         Your browser does not support the video tag.
                     </video>
                     </div>
                </div>

            </div>
            <div class="row">
                <div class="col">
                   <h5 class=""><b>Backend</b></h5>
                   <div class="d-flex">
                      <ToolIcon image={require("../images/firebase_logo.png")} title="Firebase"/>
                  </div>
                  <p>
                      <b>Authentication</b><br/>
                         For user authentication, Firebase's Authentication service is used. Using the unique ID provided, a firestore account is created.<br/><br/>
                      <b>Firestore</b><br/>
                         Firestore is used to store user information and for instant chats. The security rules are well set for each user to access their private and other users public data only.<br/><br/>
                      <b>Messaging</b><br/>
                         Messaging service is used to notify a user of new activities such as new game or when user plays. Differently from the previous services, this requires a server code to be written to function. That's where the following service fits in.<br/><br/>
                      <b>Cloud Functions</b><br/>
                         The cloud function server listens to new game plays and game creation. Based on the data, when triggered, it sends a customized message to the target user.
                  </p>
               </div>
            </div>
          </div>
        );
    }
}

export default LudoPrison;
