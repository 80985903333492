import React, { Component } from "react";
import {IoLanguage} from "react-icons/io5"
import ToolIcon from "./ToolIcon"

class LanguageSkills extends Component {
  constructor(props){
    super(props);
    this.state = {
      levels:["Novice", "Advanced Beginner", "Competent","Proficient", "Expert"]
    }
  }
  render() {
    return (
      <div class="container-fluid p-lg-5">
      <div class="row">
          <div class="col col-lg-4 mt-4 skillHeader">
             <div class="d-flex align-items-center">
                <IoLanguage size={25} color="#20c997"/>
                <h4 class="display-5 font-weight-bold pt-2">Languages</h4>
             </div>
          </div>

      </div>
      <div class="row">
          <div class="col pl-4 skillSection">
              <div class="d-flex flex-wrap">
              <div class="skill">
                  <div class="d-flex">
                     <ToolIcon >
                         <h4 class="font-weight-bold">English</h4>
                     </ToolIcon>
                  </div>
                  <div class="" style={{fontSize:"12px"}}>
                      <p>
                         Certificates: <b>IELTS (7)</b>
                      </p>
                      <p>
                         Expertise: <b>{this.state.levels[3]}</b>
                      </p>
                  </div>
              </div>
              <div class="skill">
                  <div class="d-flex">
                     <ToolIcon >
                         <h4 class="font-weight-bold">Korean</h4>
                     </ToolIcon>
                  </div>
                  <div class="" style={{fontSize:"12px"}}>
                      <p>
                         Certificates: <b>TOPIK (4)</b>
                      </p>
                      <p>
                         Expertise: <b>{this.state.levels[2]}</b>
                      </p>
                  </div>
              </div>
              <div class="skill">
                  <div class="d-flex">
                     <ToolIcon >
                         <h4 class="font-weight-bold">French</h4>
                     </ToolIcon>
                  </div>
                  <div class="" style={{fontSize:"12px"}}>
                      <p>
                         Certificates: <b>DELF (B2)</b>
                      </p>
                      <p>
                         Expertise: <b>{this.state.levels[4]}</b>
                      </p>
                  </div>
              </div>
              <div class="skill">
                  <div class="d-flex">
                     <ToolIcon >
                         <h4 class="font-weight-bold">Fulani</h4>
                     </ToolIcon>
                  </div>
                  <div class="" style={{fontSize:"12px"}}>
                      <p>
                         Certificates: <b>Native</b>
                      </p>
                      <p>
                         Expertise: <b>{this.state.levels[4]}</b>
                      </p>
                  </div>
              </div>
              <div class="skill">
                  <div class="d-flex">
                     <ToolIcon >
                         <h4 class="font-weight-bold">Arabic</h4>
                     </ToolIcon>
                  </div>
                  <div class="" style={{fontSize:"12px"}}>
                      <p>
                         Certificates: <b>None</b>
                      </p>
                      <p>
                         Expertise: <b>{this.state.levels[2]}</b>
                      </p>
                  </div>
              </div>
              </div>
          </div>
      </div>
   </div>
    );
  }
}

export default LanguageSkills;
