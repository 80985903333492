import React, { Component } from "react";
import ToolIcon from "./ToolIcon"
class BanaOld extends Component {
    constructor(props) {
        super(props);
    }


    componentDidMount(){
        //this.setState({currentImage: require("../images/condition.jpg")})
    }
    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
            <div class="row">
                <div class="col mt-4">
                   <div class="d-flex justify-content-between">
                      <div class="animated bounceInLeft">
                         <img class="projectIcon" src={require("../images/bana_new.png")} />
                         <h5 class="display-5 font-weight-bold pt-2">Bana</h5>
                         <p class="mb-0">(Version 1)</p>
                      </div>
                   </div>
                   <h5 class="mt-5"><b>Mobile App</b></h5>
                   <div class="d-flex">
                       <ToolIcon image={require("../images/android_studio.png")} title="Android Studio"/>
                   </div>
                   <div class="">
                        <p>
                           A android dating mobile app developed with <b>Android Studio</b>.<br/>
                           It's the first version of the app. I developed it in 2020 while being a beginner.
                           I, recreated it from scratch after learning React Native.<br/>
                           <br/>
                        </p>

                   </div>
                </div>
                <div class="col-lg">
                     <div class="embed-responsive embed-responsive-1by1">
                     <video autoPlay muted loop style={{backgroundColor:"#fff"}}>
                         <source src={require("../images/bana/old_bana.mp4")} type="video/mp4"/>
                         Your browser does not support the video tag.
                     </video>
                     </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                   <h5 class=""><b>Backend</b></h5>
                   <div class="d-flex">
                      <ToolIcon image={require("../images/firebase_logo.png")} title="Firebase"/>
                  </div>
                  <p>
                      <b>Authentication</b><br/>
                         For user authentication, Firebase's Authentication service is used. Using the unique ID provided, a firestore account is created.<br/><br/>

                      <b>Firestore</b><br/>
                         Firestore is used to store user information and for instant chats. The security rules are well set for each user to access their private and other users public data only.<br/><br/>
                      <b>Storage</b><br/>
                         Storage is used to store user images and instant chat images. It also has well defined security rules.<br/><br/>
                      <b>Messaging</b><br/>
                         Messaging service is used to notify a user of new activities such as new message, like or match. Differently from the previous services,  this requires a server code to be written to function. That's where the following service fits in.<br/><br/>
                      <b>Cloud Functions</b><br/>
                         The cloud function server listens to new messages and likes. Based on the data, when triggered, it sends a customized message to the target user.
                  </p>
               </div>
            </div>
         </div>
        );
    }
}

export default BanaOld;
