import React, { Component } from "react";
import $ from "jquery";
import ToolIcon from "./ToolIcon"
import {IoGameController, IoSettings, IoPersonAdd} from "react-icons/io5"
import {MdAnimation, MdSwipe, MdPayment} from "react-icons/md"
import {FaInternetExplorer} from "react-icons/fa"
import { IoIosSave } from "react-icons/io"

const videos = [
require("../images/bottle_filled/bf_game.mp4"),
require("../images/bottle_filled/bf_iap.mp4"),
require("../images/bottle_filled/bf_splash.mp4"),
require("../images/bottle_filled/bf_tutorial.mp4"),
]
const texts = [
  "The game in mode 3x3. The goal is to fill a bottle by adding up bottles. The rules are: only two similar bottles can be added up. Swipe to all direction and similar bottles will be added up.",
  "This is a test in-app payment that I added to the game just to familiarize myself with the process.",
  "A nice animated splash image.",
  "A tutorial explaining the goal and the rules of the game.",
]
class BottleFilled extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
          currentVideo: videos[0],
          currentText: texts[0],
        }
        this.handlerOnFeature = this.handlerOnFeature.bind(this);
    }

    handlerOnFeature(e){
      this.setState({currentVideo: videos[e]})
      this.setState({currentText: texts[e]})
      this.videoRef.current.load()
    }


    componentDidMount(){
        //this.setState({currentImage: require("../images/condition.jpg")})
    }
    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
            <div class="row">
                <div class="col mt-4">
                   <div class="d-flex justify-content-between">
                      <div class="animated bounceInLeft">
                         <img class="projectIcon projectIconExtra" src={require("../images/bottle_filled/bf_icon.png")} />
                         <h5 class="display-5 font-weight-bold pt-2">The Bottle Filled</h5>
                      </div>
                      <div class="d-flex flex-column align-items-end">

                         <a href="https://play.google.com/store/apps/details?id=com.ibrahimawane.TheBottleFilled&hl=en_US&gl=US" target="_blank" class="mb-1">
                             <img src={require("../images/gp_icon.png")} class="storeBtn"/>
                         </a>
                         <p class="font-weight-bold mt-2">Try it yourself</p>
                      </div>
                   </div>
                   <h5 class="mt-5"><b>Mobile Game</b></h5>
                   <div class="d-flex">
                       <ToolIcon image={require("../images/unity_icon.png")} title="Unity"/>
                   </div>
                   <div class="">
                        <p>
                           A game inspired by the 2048 game developed with <b>Unity</b>.<br/>
                           I played constantly "2048" game mid-flight when going to Mauritania in 2021. I loved it!. So, I challenged myself to make a similar game in two days.
                           And surely, I did. I finished the game in about a day and half. As you know, it's lazy to recreate somebody else's game and put it in production. So added more features and changed it to bottles instead of numbers.
                           <br/>
                           <span style={{textDecoration: "underline"}}>Click the icons below to watch the associated video:</span><br/>
                        </p>
                        <div class="d-flex flex-wrap justify-content-between">
                        <button class="d-flex align-items-center p-2 btn rounded btn-success" onClick={()=> this.handlerOnFeature(0)}>
                            <IoGameController size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Game</p>
                        </button>
                        <button class="d-flex align-items-center p-2 btn rounded btn-primary" onClick={()=> this.handlerOnFeature(1)}>
                            <MdPayment size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>In-app Payment</p>
                        </button>
                        <button class="d-flex align-items-center p-2 btn rounded btn-secondary" onClick={()=> this.handlerOnFeature(2)}>
                            <MdAnimation size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Splash</p>
                        </button>
                        <button class="d-flex align-items-center p-2 btn rounded btn-info" onClick={()=> this.handlerOnFeature(3)}>
                            <MdSwipe size={20}/>
                            <p class="pl-1 font-weight-bold m-0" style={{fontSize:'13px'}}>Tutorial</p>
                        </button>
                        </div>
                        <p class="mt-3" style={{fontSize:"15px"}}>{
                          this.state.currentText
                        }</p>
                   </div>
                </div>
                <div class="col-lg">
                     <div class="embed-responsive embed-responsive-1by1">
                     <video autoPlay muted loop ref={this.videoRef} style={{backgroundColor:"#fff"}}>
                         <source src={this.state.currentVideo} type="video/mp4"/>
                         Your browser does not support the video tag.
                     </video>
                     </div>
                </div>

            </div>
          </div>
        );
    }
}

export default BottleFilled;
