import React, { Component } from "react";


class NewsFooter extends Component {
  render() {
    return (
      <div>
      <img class="pt-2" src="./news_footer_image.png" width="100%" height="auto"/>
      </div>
    );
  }
}

export default NewsFooter;