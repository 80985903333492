import React, { Component } from "react";

import ContactButtons from "../components/ContactButtons";
import $ from "jquery";
import NewsOne from "../components/NewsOne";
import NewsHeader from "../components/NewsHeader";
import NewsFooter from "../components/NewsFooter";
import Navbar from "../components/NavBar";
import NavTop from "../components/NavTop";

class News extends Component {
    componentDidMount(){
        $(document).ready(function(){
            $(".actif").removeClass("actif");
            $("#news-nav-item a").addClass("actif");
         });
    }
    render() {
        return (
            <div>
                <NavTop/>
                <Navbar/>
                <NewsHeader />
                <NewsOne />
                <NewsOne />
                <NewsOne />
                <NewsFooter/>
                <ContactButtons />
            </div>
        );
    }
}

export default News;