import React, { Component } from "react";
import $ from "jquery";

var position = 0;
var positions = [-700, -350, 0];
var titles = ["두더지 잡기", "Park Of Eden", "Remember O"];
var descriptions = ["Tap the mole that has an O on top of it please don't make the mole angry by losing!",
 "Welcome to the park that relaxes your eyes in a very special way!",
 "Remember how many Os are on the nenuphars? The number grows in each step. Try to be fast!"];

class AppTwo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trans: 0,
      now: new Date().getTime(),
      currentTitle : titles[0],
      currentDescription : descriptions[0],
      index: 0
    }
    this.moveLeft(50);
  }

  moveLeft(step) {
    setTimeout(() => {
      if (position > -700) {
        this.setState({ trans: position - step });
        position = position - step;
        if (positions.includes(position)) {
          this.removeAnimateClass();
          if (position != -700) {
            this.pause(0);
          }
          else {
            this.pause(1);
          }
        } else {
          this.moveLeft(50);
        }
      }
    }, 100);
  }
  moveRight(step) {
    setTimeout(() => {
      if (position < 0) {
        this.setState({ trans: position + step });
        position = position + step;
        this.moveRight(50);
        
      } else { this.removeAnimateClass(); this.pause(0) }
    }, 100);
  }
  pause(direction) {
    setTimeout(() => {
      //left
      this.setText();
      this.animateText(direction);
      if (direction == 0) {
        this.moveLeft(50);
      }
      //right
      else if (direction == 1) {
        this.moveRight(50);
      }
    }, 2000)
  }
  animateText(i) {
    if (i == 1) {
      $('.animText').addClass('fadeOut');
    }
    else {
      $('.animText').addClass('fadeIn');
    }
  }
  removeAnimateClass() {
    if ($('.animText').hasClass('fadeIn'))
      $('.animText').removeClass('fadeIn');
    if ($('.animText').hasClass('fadeOut'))
      $('.animText').removeClass('fadeOut');
  }
  setText(){
    if(this.state.index == 3){
      this.setState({currentTitle: titles[0]})
      this.setState({currentDescription:descriptions[0]})
      this.setState({index:1})
    }else{
      this.setState({currentTitle: titles[this.state.index]})
      this.setState({currentDescription:descriptions[this.state.index]})
      this.setState({index:(this.state.index+1)})
    }
  }

  componentDidMount() {
  }
  render() {
    return (
      <div class="appTwo p-md-5 container">
        <div class="row justify-content-md-center">
          <div class="col-sm order-2 d-flex justify-content-center">
            <div class="phone" style={{
              width: "380px", height: "650px",
              backgroundImage: "url('./phone_back.png')", backgroundSize: "100% auto", overflow: "hidden", paddingTop: "42px"
            }}>

              <div class="mask" style={{ width: "350px", height: "650px", overflow: "hidden", marginLeft: "15px", }}>
                <div class="myslider" style={{ transform: "translateX(" + this.state.trans.toString() + "px)", listStyle: "none", width: "1050px" }}>
                  <ul style={{ listStyle: "none", padding: "0", listStyleType: "none" }}>
                    <li style={{ display: "inline-block" }}>
                      <img class="current product_0" src="../screen_1.png" style={{ width: "350px", height: "auto" }} />
                    </li>
                    <li style={{ display: "inline-block" }}>
                      <img class="current product_0" src="../screen_2.png" style={{ width: "350px", height: "auto" }} />
                    </li>
                    <li style={{ display: "inline-block" }}>
                      <img class="current product_0" src="../screen_4.png" style={{ width: "350px", height: "auto" }} />
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div class="col-sm order-1 order-md-12 animText animated">
            <h1 class="phone-screen-title display-3 font-weight-bold pt-sm-5 mt-sm-5 text-center">{this.state.currentTitle}</h1>
          <p class="phone-screen-description text-center">{this.state.currentDescription}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default AppTwo;