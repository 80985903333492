import React, { Component } from "react";
import $ from "jquery";

class CSOne extends Component {
  componentDidMount(){
    $( '.tags .btn' ).on( 'click', function () {
        $( '.tags' ).find( 'input.btn-primary' ).removeClass( 'btn-primary' );
        $( this ).addClass( 'btn-primary' );
        
    });
}
  render() {
    return (
      <div class="container-fluid">
        <div class="row pt-5 pl-xl-5 justify-content-center">
          <div class="col-xl-10">
            <h1 class="display-4 " style={{ fontWeight: "900" }}>도움이 필요하신가요?</h1>
          </div>
        </div>
        <div class="row pt-1 pl-xl-5 justify-content-center">
          <div class="col-10">
            <input type="text" class="form-control border border-light" placeholder="궁금한 것을 검색하시면 더 빠르게 찾으실 수 있습니다." />
          </div>
        </div>
        <div class="tags row pt-4 px-xl-5 mx-xl-5 justify-content-center">
          <input class="btn btn-primary " type="button" value="오투스 기기" />

          <input class="btn " type="button" value="App" />

          <input class="btn " type="button" value="렌탈" />

          <input class="btn" type="button" value="결제" />

          <input class="btn" type="button" value="A/S관련" />

          <input class="btn " type="button" value="배송문의" />

          <input class="btn " type="button" value="개인정보 " />

          <input class="btn" type="button" value="환경설정" />

          <input class="btn " type="button" value="기타" />

        </div>
        <div class="row pt-5 pl-xl-5 justify-content-center">
          <div class="col-xl-10">
            <h5 class=" " style={{ fontWeight: "900" }}>서비스 이용</h5>
            <a href="#" class="text-dark question-button"><div class="border-top d-flex align-items-center" >
              <h6 class="p-3 align-self-center font-weight-bold" style={{ marginBottom: "0", padding: "0" }}>Q. This is a question </h6>
              <p class="p-3 ml-auto" style={{ marginBottom: "0", padding: "0" }}>&#10148;</p>
            </div>
            </a>
            <a href="#" class="text-dark question-button"><div class="border-top d-flex align-items-center" >
              <h6 class="p-3 align-self-center font-weight-bold" style={{ marginBottom: "0", padding: "0" }}>Q. This is a question </h6>
              <p class="p-3 ml-auto" style={{ marginBottom: "0", padding: "0" }}>&#10148;</p>
            </div>
            </a>
            <a href="#" class="text-dark question-button"><div class="border-top d-flex align-items-center" >
              <h6 class="p-3 align-self-center font-weight-bold" style={{ marginBottom: "0", padding: "0" }}>Q. This is a question </h6>
              <p class="p-3 ml-auto" style={{ marginBottom: "0", padding: "0" }}>&#10148;</p>
            </div>
            </a>
            <a href="#" class="text-dark question-button"><div class="border-top d-flex align-items-center" >
              <h6 class="p-3 align-self-center font-weight-bold" style={{ marginBottom: "0", padding: "0" }}>Q. This is a question </h6>
              <p class="p-3 ml-auto" style={{ marginBottom: "0", padding: "0" }}>&#10148;</p>
            </div>
            </a>
            <a href="#" class="text-dark question-button"><div class="border-top d-flex align-items-center" >
              <h6 class="p-3 align-self-center font-weight-bold" style={{ marginBottom: "0", padding: "0" }}>Q. This is a question </h6>
              <p class="p-3 ml-auto" style={{ marginBottom: "0", padding: "0" }}>&#10148;</p>
            </div>
            </a>
          </div>

        </div>
      </div>
    );
  }
}

export default CSOne;