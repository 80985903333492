import React, { Component } from "react";
import $ from "jquery";
import ToolIcon from "./ToolIcon"
import { BiStats, BiBarcodeReader } from "react-icons/bi"
import { IoNotifications, IoChatbubbleEllipsesSharp, IoLogIn, IoSettings } from "react-icons/io5"
import { MdAnimation, MdSwipe } from "react-icons/md"
import { RiHeartsFill } from "react-icons/ri"
import { FcAndroidOs } from "react-icons/fc"

const videos = [
    require("../images/bana/bana_matching.mp4"),
    require("../images/bana/bana_chatting.mp4"),
    require("../images/bana/bana_notifications.mp4"),
    require("../images/bana/bana_settings.mp4"),
    require("../images/bana/bana_login.mp4"),
]
const texts = [
    "A process where the user likes or passes on a another user in a tinder swiping fashion. When they match, the users are added as friends and can chat. A third party library is used for the swiping feature.",
    "An instant chat including a feature of sending images and stickers. A notification is received when the app is in background. Click the next button for more on notifications.",
    "Real-time notifications are sent to a user who receives a message, who has been liked or has been matched. This feature is achieved by combining Firebase Messaging and Notifee.",
    "In the settings, user information can be changed, including pictures, name, etc. Also user preferences can be set, such as distance of users shown to them. Location feature is therefore used.",
    "Though only the phone login process is shown in the video, there is also a facebook login feature. After login, user data is retrieved and they are redirected to the main page. Firebase Authentication is used along Facebook login.",
]

class Bana extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
            currentVideo: null,
            currentText: texts[0],
        }
        this.handlerOnFeature = this.handlerOnFeature.bind(this);
    }

    handlerOnFeature(e) {
        this.setState({ currentVideo: videos[e] })
        this.setState({ currentText: texts[e] })
        this.videoRef.current.load()
    }


    componentDidMount() {
        //this.setState({currentImage: require("../images/condition.jpg")})
    }
    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
                <div class="row">
                    <div class="col mt-4">
                        <div class="d-flex justify-content-between">
                            <div class="animated bounceInLeft">
                                <img class="projectIcon" src={require("../images/bana_new.png")} />
                                <h5 class="display-5 font-weight-bold pt-2">Bana</h5>
                                <p class="mb-0">(Version 2)</p>
                            </div>
                            <div class="d-flex flex-column align-items-end">
                                <a href="http://211.178.39.229:5000/bana.apk" target="_blank" class="mb-1 btn btn-light rounded d-flex align-items-center">
                                    <FcAndroidOs size={30} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Apk Download</p>
                                </a>
                                <p class="font-weight-bold mt-2">Try it yourself</p>
                            </div>
                        </div>
                        <h5 class="mt-5"><b>Mobile App</b></h5>
                        <div class="d-flex">
                            <ToolIcon image={require("../images/react_native.png")} title="React Native" />
                        </div>
                        <div class="">
                            <p>
                                A hybrid dating mobile app developed with <b>React Native</b>.<br />
                                It's also a second version of the app. I developed the first version in 2020 with <b>Android Studio</b> only.
                                I, recreated it in a month from scratch after learning React Native.<br />
                                As the app is <b>NOT</b> yet <b>in production</b>, you can download the apk and give it a try. Be informed that Facebook login only works during production. Therefore please log in with your phone number.
                                <br />
                                <span style={{ textDecoration: "underline" }}>Click the icons below to watch the associated video:</span><br />
                            </p>
                            <div class="d-flex flex-wrap justify-content-between">
                                <button class="d-flex align-items-center p-2 btn rounded btn-light" onClick={() => this.handlerOnFeature(0)}>
                                    <RiHeartsFill size={20} color="red" />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Matching</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-secondary" onClick={() => this.handlerOnFeature(1)}>
                                    <IoChatbubbleEllipsesSharp size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Instant Chat</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-success" onClick={() => this.handlerOnFeature(2)}>
                                    <IoNotifications size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Notifications</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-primary" onClick={() => this.handlerOnFeature(3)}>
                                    <IoSettings size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Settings</p>
                                </button>
                                <button class="d-flex align-items-center p-2 btn rounded btn-info" onClick={() => this.handlerOnFeature(4)}>
                                    <IoLogIn size={20} />
                                    <p class="pl-1 font-weight-bold m-0" style={{ fontSize: '13px' }}>Facebook/Phone Login</p>
                                </button>
                            </div>
                            <p class="mt-3" style={{ fontSize: "15px" }}>{
                                this.state.currentText
                            }</p>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="embed-responsive embed-responsive-1by1">
                            <video autoPlay muted loop ref={this.videoRef} style={{ backgroundColor: "#fff" }}>
                                <source src={this.state.currentVideo ? this.state.currentVideo : require("../images/bana/bana_matching.mp4")} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h5 class=""><b>Backend</b></h5>
                        <div class="d-flex">
                            <ToolIcon image={require("../images/firebase_logo.png")} title="Firebase" />
                        </div>
                        <p>
                            <b>Authentication</b><br />
                            For user authentication, Firebase's Authentication service is used. Using the unique ID provided, a firestore account is created.<br /><br />

                            <b>Firestore</b><br />
                            Firestore is used to store user information and for instant chats. The security rules are well set for each user to access their private and other users public data only.<br /><br />
                            <b>Storage</b><br />
                            Storage is used to store user images and instant chat images. It also has well defined security rules.<br /><br />
                            <b>Messaging</b><br />
                            Messaging service is used to notify a user of new activities such as new message, like or match. Differently from the previous services,  this requires a server code to be written to function. That's where the following service fits in.<br /><br />
                            <b>Cloud Functions</b><br />
                            The cloud function server listens to new messages and likes. Based on the data, when triggered, it sends a customized message to the target user.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bana;
