import React, { Component } from "react";

class ToolIcon extends Component {
  constructor(props) {
    super(props);
}
    render() {
        return (
              <div class="d-flex flex-column align-items-center mr-3">
                 {this.props.image && <img class="toolIcon" src={this.props.image} />}
                 {this.props.children}
                 <p class="text-center mt-1 toolText font-weight-bold" style={{color: this.props.color ? this.props.color : "#000"}}>{this.props.title}</p>
              </div>
        );
    }
}

export default ToolIcon;
