import React, { Component } from "react";
import $ from "jquery";
import ToolIcon from "./ToolIcon"

class OtusPlusReport extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class="container-fluid p-lg-5 rowShadow">
            <div class="row">
                <div class="col mt-4">
                   <div class="d-flex justify-content-between">
                      <div class="animated bounceInLeft">
                         <img class="projectIcon" src={require("../images/pdf_icon.png")} />
                         <h5 class="display-5 font-weight-bold pt-2">Otus Plus PDF Report</h5>
                      </div>
                   </div>
                   <h5 class="mt-5"><b>Fontend</b></h5>
                   <div class="d-flex">
                       <ToolIcon image={require("../images/reactjs_icon.png")} title="React JS"/>
                   </div>
                   <div class="">
                        <p>
                           A React JS app that opens up and creates a <b>PDF</b> report file. The user's data that is shown in the file is fetched from a <b>Springboot</b> backend.
                           Go to the section below for more about Springboot. <br/>
                           In reality, the download happens automatically when the browser is open (either by a python server or human) at this url.<br/><br/>
                           <b>How it works?</b><br/>
                           A computer with a monitor is needed to open a browser which will get screenshot when the page shows up and the images will be converted to PDF.
                           Then the user can download the PDF file through the app.
                        </p>
                   </div>
                </div>
                <div class="col-lg">
                     <div class="embed-responsive embed-responsive-16by9">
                     <video autoPlay muted loop ref={this.videoRef} style={{backgroundColor:"#fff"}}>
                         <source src={require("../images/otus_plus/report_video.mp4")} type="video/mp4"/>
                         Your browser does not support the video tag.
                     </video>
                     </div>
                </div>

            </div>
            <div class="row">
                <div class="col">
                   <h5 class=""><b>Backend</b></h5>
                   <div class="d-flex">
                      <ToolIcon image={require("../images/spring_icon.png")} title="Springboot"/>
                  </div>
                  <p>
                      <b>Structure</b><br/>
                      The Springboot application retrieves the data from a MySQL database. Then it will do the computations and send the new data to the client (the React JS app in this case).
                  </p>
               </div>
            </div>
          </div>
        );
    }
}

export default OtusPlusReport;
