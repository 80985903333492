import React, { Component } from "react";
import $ from "jquery";


class AppFour extends Component {
  render() {
    return (

      <div class="container-fluid p-xl-5">

        <div class="row ml-3 px-md-5 my-5 border-bottom">
          <div class="col-xl-3 justify-content-center">
            <h2 class="display-3 text-center text-xl-left" style={{fontWeight:"650"}}>ENJOY <br />
          FIND<br /> EASY</h2>
          </div>
          <div class="col">
            <h1 class="d-flex justify-content-center justify-content-xl-end" style={{fontWeight:"350", fontSize:"5vw"}}>
              지그재그는 좋아하는 상품을<br />
              보다 더 쉽게 찾을 수 있는<br />
              온라인 쇼핑 환경을 만듭니다.</h1>
          </div>
        </div>
        <div class="row ml-3 px-md-5">
          <div class="col">
            <h3 class="display-5">지금 바로 지그재그를 만나보세요!</h3>
          </div>
          <div class="col-5 w-100 d-none d-xl-block">
            <a href="#" >
              <img class="w-50 px-1" src="app_store_orange.png" />
            </a>
            <a href="#">
              <img class="animated fadeInUp w-50 px-1" src="google_play_orange.png" />
            </a>
          </div>
        </div>

      </div>
    );
  }
}

export default AppFour;