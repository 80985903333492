import React, { Component } from "react";
import $ from "jquery";

class NavTop extends Component {

    render() {
        return (

            <div class="container-fluid">
                <div class="nav-top row d-flex justify-content-end pr-1">
                <ul>
                    <li class="login-link px-1"><a href="/login">Login</a></li>
                    <li class="join-link px-1"><a href="/join">Join</a></li>
                    <li class="order-link px-1"><a>Order</a></li>
                    <li class="lang-link px-1"><a>KOR</a></li>
                </ul>
                </div>
                <div class="row d-flex justify-content-end mr-5">
                <div class="points mr-2">
                    <div class="tri"></div>
                    <div class="rect text-center pt-1 text-danger font-weight-bold">+10,000 P</div>
                </div>
                </div>
            </div>
        );
    }
}

export default NavTop;