import React, { Component } from "react";

class ContactButtons extends Component {
    render() {
        return (
            <div class="contact-buttons">
            <a href="https://www.facebook.com/zeromama.waa"><img class="facebook-button" src={require("../images/facebook_logo.png")} /></a>
            <a href="https://www.youtube.com/channel/UCSZP_Qi9gQR7CVFnK99zu9g"><img class="youtube-button" src={require("../images/youtube_logo.png")} /></a>
        </div>
        );
    }
}

export default ContactButtons;
