import React, { Component } from "react";
import HomeOne from "../components/HomeOne";
import HomeTwo from "../components/HomeTwo";
import ContactButtons from "../components/ContactButtons";
import NavBar from "../components/NavBar";
import $ from "jquery";
import NavTop from "../components/NavTop";

class Home extends Component {
    componentDidMount(){
        $(document).ready(function(){
            $(".actif").removeClass("actif");
            $("#home-nav-item a").addClass("actif");
         });
    }
    render() {

        return (
            <div>
                {/*<NavTop/>*/}
                <NavBar />
                <HomeOne />
                <ContactButtons />
            </div>


        );
    }
}

export default Home;
