import React from 'react';
import { Route, Switch, BrowserRouter as Router, BrowserRouter } from "react-router-dom";
import Home from "./screens/Home";
import Projects from "./screens/Projects";
import Apps from "./screens/Apps";
import Skills from "./screens/Skills";
import News from "./screens/News";
import CS from "./screens/CS";
import About from "./screens/About";
import Login from './screens/Login';
import Join from './screens/Join';
import NavBar from "./components/NavBar";

function ComingSoon () {
  return (
    <div>
      <NavBar/>
      <p class="text-center mt-5">Coming soon...</p>
    </div>
  )
}
function App() {
  return (
    <Router>
      <main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/skills" component={Skills} />
          <Route exact path="/stories" component={ComingSoon} />
          <Route exact path="/hobbies" component={ComingSoon} />
          <Route exact path="/certificates" component={ComingSoon} />
          <Route exact path="/career" component={ComingSoon} />
        </Switch>
      </main>

    </Router>

  );
}

export default App;
