import React, { Component } from "react";
import { FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { GoMail } from "react-icons/go";

class HomeOne extends Component {
  render() {
    return (
      <section class="container-xl overflow-hidden p-4 p-md-5 p-lg-5 p-xl-5 bg-white">
      <div class="col m-0 p-0 my-1 row align-items-center">
          <img class="profileImg" src={require("../images/profile.jpg")} alt="profile" ></img>
          <div class="py-4 pl-1">
             <h5 class="display-5 font-weight-bold pt-2">Ibrahima Wane (28)</h5>
             <p class="lead font-weight-bold"> Full-stack Software Developer</p>
          </div>
          <div class="col">
              <div class="row justify-content-end align-items-center my-1">
                  <FaPhone color="#20c997"/>
                  <p class="font-weight-bold text-center m-0 ml-1"> +82 1029001957</p>
              </div>
              <div class="row justify-content-end align-items-center my-1">
                  <GoMail color="#20c997"/>
                  <p class="font-weight-bold text-center m-0 ml-1"> ibrahimawane35@gmail.com</p>
              </div>
              <div class="row justify-content-end align-items-center my-1">
                  <FaMapMarkerAlt color="#20c997"/>
                  <p class="font-weight-bold text-center m-0 ml-1">South Korea, Seoul</p>
              </div>

          </div>
      </div>


      <div class="my-3">
          <p class="">
          A passionate full-stack software developer with considerable programming experience in <b>Mobile App</b>, <b>Mobile Game</b>, <b>Web</b> and <b>Electronics</b>.
          With various tools, I created many applications with a multitude of features.<br/>

          Otus Plus is so far the biggest and my favorite mobile app that I developed. I made this app for Edenlux to mainly control a wearable device.
          I also developed personal games for production and for practice. My most popular game (approx. 2k downloads) is the first game that I developed.
          However, this very website is the only in-production website that I developed.<br/>

          The tools that I use for development include React Native, Unity, React JS and Android Studio for frontend and Springboot, Python and Firebase Functions for backend.
          I effortlessly structre data for NoSQL as well as SQL databases across all my projects. <br/><br/>
          </p>
      </div>

      <div class="my-3 d-flex flex-column">
          <h5><b>My journey</b></h5>
          <p class="">
          From an early age I was fascinated by technology when I saw a simple lightbulb wired to a D battery lighting up. I was so curious about how it worked.
          Since then, I knew this is what I will do when I grow up. As I grew up and graduated from High School, I chose Networks and telecommunications as my major.
          Though, I believed that programming was made for me, my grades weren't really good in the subject. Luckily, my overall grade was good enough for me to graduate and get the Korean Government Scolarship.
          I learned Korean language for one year and started my Master's degree afterwards at Pukyong National University.
          The beginning wasn't easy! But after I started working on a practical IoT project, dots started to connect and everything that I learned since my bachelor's degree started making more sense.
          Few months later, I was appointed to teach an IoT class to bachelor students at the university. I taught them about programming, electronics and communications.
          </p>
          <div class="align-self-center pictureParent">
          <img class="img-fluid" src={require("../images/iot_class_group_photo.jpg")} alt="cover"></img>
          <p class="pictureLabel py-1">*Picture of me and students during the last class.</p>
          </div>
          <p class="">
          After I graduated from Pukyong National University, In August 2019, I went back to my home country.
          Having spent two months in Mauritania, I came back to Korea and had to make a good portfolio and resume to apply for jobs. So, I bought a brand new Macbook Pro laptop and started to work on my Ludo Prison game.
          After two months, I couldn't wait to upload my completed game to Google Play store. While waiting for the release to be approved, I started creating my portfolio and resume. Thankfully, as all went as planned, I applied for many jobs.
          It didn't take a day and I started receiving calls for job interview.
          This whole process and my work experience strengthened my skills in various aspects of software developement.
          Please check out my <a href="/projects"> Projects</a> and  <a href="/skills">Skills</a> for more information.
          </p>
      </div>

      <div class="my-3 d-flex flex-column">
          <h5><b>My goals</b></h5>
          <p class="">
          My main goal in life is to become successful and change people's lives for the better. The easiest and obvious route towards this purpose is to do what I am best at and share the knowledge.
          I have made efforts to introduce young Mauritanians to the new technologies. I gave free online electricity and information communication classes in my native language, Fulani, few years ago.
          It was well received as they seldom get the chance to learn these subjects in the language they truly understand. This gave me the courage to propose a one-month free in-person IoT class to students with some knowledge in computer science.
          Many people wanted to join but unfortunately due to some limitations only 20 people could attend. I introduce them to electronics (microcontrollers, actuators, sensors, etc), information communications (serial transmission, bluetooth, etc) and software development (arduino, android).
          </p>
          <div class="align-self-center pictureParent">
          <img class="img-fluid" src={require("../images/iot_class.jpg")} alt="cover"></img>
          <p class="pictureLabel py-1">*The poster.</p>
          </div>
          <p class="">
          
          </p>
      </div>

      <br />
  </section>
    );
  }
}

export default HomeOne;
